"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineHomePage = exports.HomePage = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lotto_table_1 = require("../lotto-table/lotto-table");
class HomePage extends HTMLElement {
    constructor() {
        super();
        this.innerHTML = `<lotto-table></lotto-table>`;
    }
}
exports.HomePage = HomePage;
const defineHomePage = (name) => {
    (0, lotto_table_1.defineLottoTable)('lotto-table');
    (0, lib_es2015_1.defineIfUndefined)(name, HomePage);
};
exports.defineHomePage = defineHomePage;
