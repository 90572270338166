"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineLottoTable = exports.LottoTable = void 0;
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class LottoTable extends HTMLElement {
    constructor() {
        super();
        this.lottoSequences = [];
        this.oddThreeEvenThreeSequences = [];
        this.initialSequence = (() => {
            const ret = [];
            for (let i = 1; i < 46; i++) {
                ret.push(i);
            }
            return ret;
        })();
        this.generateLottoSequences = () => {
            this.lottoSequences = [];
            this.oddThreeEvenThreeSequences = [];
            this.randomPermutation();
            const operationSequence = [];
            for (let i = 0; i < 45; i++)
                operationSequence[i] = this.initialSequence[i];
            while (operationSequence.length > 6) {
                const pivot = this.getRandomInteger(operationSequence.length);
                const extractedSequence = [];
                if (pivot + 5 > operationSequence.length) {
                    const tempLength = operationSequence.length - pivot;
                    extractedSequence.push(...operationSequence.splice(pivot, tempLength));
                    extractedSequence.push(...operationSequence.splice(0, 6 - tempLength));
                }
                else {
                    extractedSequence.push(...operationSequence.splice(pivot, 6));
                }
                this.lottoSequences.push(extractedSequence);
            }
            this.lottoSequences.push(operationSequence);
            // additional logic. Ilhyung's requests
            this.lottoSequences.forEach((seq) => {
                if (seq.length !== 6)
                    return;
                let oddCount = 0;
                seq.forEach((num) => {
                    if (num % 2 === 1)
                        oddCount++;
                });
                if (oddCount === 3)
                    this.oddThreeEvenThreeSequences.push(seq);
            });
            const basicTable = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.lotto-sequence.basic');
            basicTable.style.setProperty('display', 'grid');
            basicTable.style.setProperty('grid-template-columns', '1fr 1fr 1fr 1fr 1fr 1fr');
            const odd3even3Table = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'div.lotto-sequence.odd-three-even-three');
            odd3even3Table.style.setProperty('display', 'grid');
            odd3even3Table.style.setProperty('grid-template-columns', '1fr 1fr 1fr 1fr 1fr 1fr');
            (0, lib_es2015_1.removeAllChildren)(basicTable);
            this.lottoSequences.forEach(seq => seq.forEach(num => basicTable.appendChild((0, lib_es2015_1.buildNodeByOuterHTML)(`<div class="cell">${num}</div>`))));
            (0, lib_es2015_1.removeAllChildren)(odd3even3Table);
            this.oddThreeEvenThreeSequences.forEach(seq => seq.forEach(num => odd3even3Table.appendChild((0, lib_es2015_1.buildNodeByOuterHTML)(`<div class="cell">${num}</div>`))));
        };
        this.randomPermutation = () => {
            const round = this.getRandomIntegerFromOne(0);
            for (let i = 0; i < round; i++) {
                const firstIdx = this.getRandomIntegerFromOne(45) - 1;
                const secondIdx = this.getRandomIntegerFromOne(45) - 1;
                if (firstIdx === secondIdx)
                    continue;
                const temp = this.initialSequence[firstIdx];
                this.initialSequence[firstIdx] = this.initialSequence[secondIdx];
                this.initialSequence[secondIdx] = temp;
            }
        };
        this.getRandomInteger = (mod) => {
            const randomNumber = Math.floor(Math.random() * 10000000);
            if (mod < 1)
                return randomNumber;
            return randomNumber % mod;
        };
        this.getRandomIntegerFromOne = (mod) => {
            return this.getRandomInteger(mod) + 1;
        };
        this.style.setProperty('display', 'block');
        this.innerHTML = `
    <div class="generating-btn-container">
      <button class="generate-btn">Get Lotto Sequences</button>
    </div>
    <div>
      <div class="table-name">Hodol's logic</div>
      <div class="lotto-sequence basic"></div>
    </div>
    <div>
      <div class="table-name">Ilhyung's logic</div>
      <div class="lotto-sequence odd-three-even-three"></div>
    </div>`;
        (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.generate-btn').addEventListener('click', () => {
            this.generateLottoSequences();
        });
    }
}
exports.LottoTable = LottoTable;
const defineLottoTable = (name) => (0, lib_es2015_2.defineIfUndefined)(name, LottoTable);
exports.defineLottoTable = defineLottoTable;
